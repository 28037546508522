
.card-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}
.card-container {

  }
  

  .card {
    width: 400px; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    margin: 0 0px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    transition: transform 0.5s, opacity 0.5s, filter 0.5s; /* Add 'filter' to the transition properties */
    transform: scale(0.8);
    opacity: 0.5;
    filter: blur(5px); /* Add the 'blur' filter and adjust the blur radius (5px in this example) */
  }
  

.card.active {
  width: 400px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed */
  transform: scale(1);
  opacity: 1;
  z-index: 1;
  filter: blur(0px); 
}
@media (min-width: 600px) {
  .card {
    width: 500px; /* Set the width to 100% when screen width is greater than 600px */
  }
  .card.active {
    width: 500px; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    transform: scale(1);
    opacity: 1;
    z-index: 1;
    filter: blur(0px); 

  }
}

@media  (min-width: 1100px) and (min-height: 1030px) {
  .card {
    width: 600px; /* Set the width to 600px when screen width is greater than 1200px and screen height is greater than 1100px */
  }
  .card.active {
    width: 600px; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    transform: scale(1);
    opacity: 1;
    z-index: 1;
    filter: blur(0px);
  }
}

